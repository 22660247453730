@import 'assets/styles/imports/variables';

.dropdown {
	display: -webkit-inline-box;
  	display: -ms-inline-flexbox;
  	display: inline-flex;
  	position: relative;
  	vertical-align: top;
}
.dropdown-menu {
  	left: 0;
  	padding-top: 4px;
  	top: 100%;
  	z-index: 20;
	position: absolute;
	text-align: left;
	background-color: $agres-white;
	min-width: 12rem;
	padding: 5px;
	border: none;
	border-radius: none;
	box-shadow: $agres-shadow-dropdown;
	transition: max-height 0.5s;
}
.dropdown-menu.show {
	display: block;
}
.dropdown-item {
	display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
	color: $agres-primary;
	padding: 10px;

	span {
		padding-left: 15px;
	}

	&:hover {
		color: $agres-primary;
		text-decoration: none;
		background-color: $agres-background;
	}
	&:focus {
		color: $agres-primary;
		text-decoration: none;
		background-color: $agres-background;
	}
	&:disabled {
		color: $agres-disabled;
		pointer-events: none;
		background-color: transparent;
	}
}
