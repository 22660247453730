table {
    border-collapse: separate;
    border-spacing: 0 1em;
}

.table {
    &__thead {
        &--th {
            background-color: #fff;
            color: #545965;
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
            cursor: pointer;
            border: 0 !important;
            padding: 20px 8px !important;
        }

        &--select {
            appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            -webkit-appearance: none;
            width: 17px;
            height: 17px;
            margin: 0 0 0 5px !important;
            vertical-align: middle;
            border: 2px solid #beccd7;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                border-color: #08d26f;
            }

            &:checked {
                background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjMDFiOWQxIi8+Cjwvc3ZnPgo=);
                background-position: center;
                background-size: 7px;
                background-repeat: no-repeat;
                border-color: #08d26f;
            }
        }
    }

    &__row {
        border-bottom: 2px solid #becad2;
        background-color: #fff;

        &--td {
            padding: 12px 8px !important;
            vertical-align: middle !important;
            color: #53646f;
            font-size: 13px;
            font-weight: 400;
            position: relative;
            line-height: 18px !important;
            border: 0 !important;

            &---img {
                width: 55px;
                height: auto;
                display: inline-block;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                vertical-align: middle;
            }
        }

        &--info {
            display: inline-block;
            padding-left: 12px;
            vertical-align: middle;

            &---text {
                color: #53646f;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 0px;
            }

            &---small {
                color: #9eabb4;
                font-weight: 300;
                font-size: 12px;
            }
        }

    }
}


@media screen and (max-width: 991px) {

    .table {
        &__thead {
            display: none;

            &--select {
                display: none;
            }
        }

        &__row {
            display: inline-block;
            border: 0;
            background-color: #fff;
            width: calc(33.3% - 10px);
            margin-right: 10px;
            margin-bottom: 10px;

            &--td {
                display: block;
                text-align: center;
                padding: 8px !important;

                &:before {
                    content: attr(data-column);
                    color: #9eabb4;
                    font-weight: 500;
                    font-size: 12px;
                    text-transform: uppercase;
                    display: block;
                }

                &---img {
                    width: 55px;
                    height: auto;
                    margin-bottom: 10px;
                }

                &-footer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    padding-bottom: 20px;

                    margin-bottom: 2rem;

                    button {
                        width: 90%;
                        background-color: #5b5b5f;
                        color: white;
                        display: inline-block;
                        cursor: pointer;

                        box-shadow: inset 0 0 0 0 #5b5b5f;
                        -webkit-transition: all ease 0.8s;
                        -moz-transition: all ease 0.8s;
                        transition: all ease 0.8s;

                        &:hover {
                            box-shadow: inset 210px 0 0 0 #35415a;
                            color: #fff;
                        }
                    }

                }

                &:hover {
                    box-shadow: inset 250px 0 0 0 #f2f2f2;
                }
            }

            &--info {
                display: block;
                padding-left: 0;
            }

        }
    }
}


@media screen and (max-width: 680px) {
    .table__row {
        width: calc(50% - 10px);
    }
}

@media screen and (max-width: 480px) {
    .table__row {
        width: 100%;
    }
}

.pagination {
    margin: 10px;
    padding: 0;
    text-align: center;

    &__left {
        float: left !important;
    }

    &__right {
        float: right !important;

        li {
            display: inline;
            background-color: #fff;
            color: #4A4A49;
            padding: 5px;

            a {
                display: inline-block;
                text-decoration: none;
                padding: 5px 10px;
                color: #4A4A49;
                -webkit-transition: background-color 0.3s;
                transition: background-color 0.3s;

                &.active {
                    background-color: #08d26f;
                    color: #fff;
                }

                &:hover:not(.active) {
                    color: #fff;
                    background-color: #4A4A49;
                }
            }
        }
    }
}