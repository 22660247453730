@import 'assets/styles/imports/variables';

.dropZone {
  border: 2px dashed $agres-border;
  background-color: $agres-background;
  display: block;

  &__wrapper {
    padding: 2rem 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $agres-description;
    z-index: 20;
    cursor: pointer;

    &--upload {
      position: relative;
      width: 5rem;
      height: 5rem;
      background: $agres-white;
      border-radius: 50%;
      padding: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;

      &---icon {
        color: $agres-description;
      }
    }

    &--uploadCheck {
      position: relative;
      width: 5rem;
      height: 5rem;
      background: $agres-success;
      border-radius: 50%;
      padding: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;

      &---icon {
        color: $agres-white;
      }
    }

    &--title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 1.5rem;
      color: $agres-description;
      margin-bottom: 0rem;
    }
  }

  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }
}
