.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: $agres-white;
    background-clip: border-box;
    margin-bottom: 1rem;
    text-decoration: unset;
    border: 1px solid $agres-border;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;


    &__body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem 1rem;
    }

    &__title {
        margin-bottom: .75rem
    }

    &__subtitle {
        margin-top: -.375rem;
        margin-bottom: 0
    }
    &__center {
        text-align: center;
    }

    &__header {
        font-size: $agres-font-size;
        border-bottom: 1px solid $agres-border;
        background-color: $agres-white;
        padding: 1rem;
    }

    &__footer {
        border-top: 1px solid $agres-border;
        padding: 1rem;
        background-color: $agres-white;
    }

    &__block {
        padding: 1.25rem;

        &::after {
            content: "";
            display: table;
            clear: both
        }
    }
}

