.a-column--one {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.a-column--two {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
}
.a-column--tree {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  gap: 1rem;
}
.display-grid {
  display: grid;
}
.a-column--collumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.agr--list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
}

.cardList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 1rem;
}
@media only screen and (max-width: 1023px) {
  .searchMobile {
    margin-top: 55px;
  }
}
