hr {
    margin: 1rem 0;
    color: #7d8287;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1, h1 {
    font-size: calc(1.15625rem + 1.175vw)
}

@media (min-width:1200px) {
    .h1, h1 {
        font-size: 2.0125rem
    }
}

.h2, h2 {
    font-size: calc(1.31rem + .72vw)
}

@media (min-width:1200px) {
    .h2, h2 {
        font-size: 1.85rem
    }
}

.h3, h3 {
    font-size: calc(1.28688rem + .4425vw)
}

@media (min-width:1200px) {
    .h3, h3 {
        font-size: 1.61875rem
    }
}

.h4, h4 {
    font-size: calc(1.26375rem + .165vw)
}

@media (min-width:1200px) {
    .h4, h4 {
        font-size: 1.3875rem
    }
}

.h5, h5 {
    font-size: 1.15625rem
}

.h6, h6 {
    font-size: .925rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}


.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    color: $agres-primary !important
}

.text-secondary {
    color: $agres-placeholder !important
}

.text-success {
    color: $agres-success!important
}

.text-info {
    color: $agres-info!important
}

.text-warning {
    color: $agres-warning !important
}

.text-danger {
    color: $agres-danger !important
}

.text-light {
    color: $agres-light-primary !important
}

.text-dark {
    color: $agres-primary !important
}

.text-white {
    color: $agres-white !important
}


.text-black {
    color: $agres-primary !important
}

.text-body {
    color: $agres-primary !important
}

.text-muted {
    color: #a4a9b4 !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}