@import 'assets/styles/imports/variables';

.panel {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border: none;
  box-shadow: none;

  &__group {
    &--buttons {
      display: flex;
      margin: 0 6px 6px 0;
      flex-wrap: wrap;
    }
  }

  &__search {
    padding: 5px 0 4px;
  }
}

@media only screen and (max-width: 1023px) {
  .panel {
    background-color: $agres-white;
    padding: 10px;
    top: 55px;
    position: fixed;
    left: 5px;
    right: 15px;
    transition: left 0.3s ease;
    z-index: 1;
    display: block;
    border-radius: 0;
    align-items: center;

    &__group {
      justify-content: space-between;
    }

    &__search {
      margin-bottom: 0px;
      padding: 0;
    }
  }
}

.pwa {
  position: fixed;
  margin: 0;
  left: 0;
  bottom: 0;
  padding: 10px;
  width: 100%;
  z-index: 99999;
  background-color: $agres-white;
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $agres-border;
  
  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  

    &--info {
      text-align: center;
      display: inline-block;

      &---active {
        color: $agres-danger;
        font-weight: 700;
      }

      button[disabled] {
        background: none;
        opacity: 0.5;
      }

      button {
        background: none;

        i {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          text-align: center;
          font-size: 19px;
        }

        p {
          font-size: 9px;
          font-weight: 700;
          text-transform: uppercase;
          margin: 4px 0px;
          display: block;
        }
      }
    }
  }
}

.cards__mobile {
  display: none;
}

.buttons__top {
  margin-top: 20px;
}

@media only screen and (max-width: 1023px) {
  .cards__desktop {
    display: none;
  }

  .cards__mobile {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .cards__desktop {
    display: none;
  }

  .cards__mobile {
    display: block;
  }
}

.hidden__desktop {
  display: none;
}

@include devices(tablet) {
  .hidden__mobile {
    display: none;
  }
  .hidden__desktop {
    display: block;
  }
}


@include devices(desktop) {
  .pwa {
    display: none;
  }
}
