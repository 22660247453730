@import 'assets/styles/imports/variables';

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: $agres-white;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin: 0;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $agres-primary;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }

  &:disabled {
    color: $agres-disabled;
    cursor: not-allowed;
  }

  &:checked {
    &::before {
      transform: scale(1);
    }
  }
}


input[readonly] {
  background-color: $agres-light-secondary;
  cursor: not-allowed !important;
}

input:-moz-read-only {
  background-color: $agres-light-secondary;
}

input:read-only {
  background-color: $agres-light-secondary;
}

input[type='color'] {
  padding: 0rem;
}

input[type='file'] {
  width: 100%;
  color: $agres-primary;
  padding: 5px;
  background: $agres-white;
  border-radius: 0px;
  border: 1px solid $agres-border;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: $agres-primary;
  padding: 10px 20px;
  border-radius: 0px;
  color: $agres-white;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  background: $agres-border;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $agres-primary;
}

.field__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: calc(5px + 0%);
}

.field {

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__checkbox {
    display: flex;
    margin-bottom: 15px;
    font-size: $agres-font-size-small;
    line-height: 10px;
    font-weight: $agres-font-weight-medium;
    color: $agres-label;

    span {
      padding-left: 10px;
    }
  }

  &__label {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3px;
    font-size: $agres-font-size-small;
    line-height: $agres-line-height;
    font-weight: $agres-font-weight-medium;
    color: $agres-label;
  }

  &__search {
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }

  &__wrap {
    position: relative;
  }

  &__view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 61px;
  }

  select::-ms-expand {
    display: none;
  }


  &__select {
    select {
      appearance: none;
      -webkit-appearance: none;
      display: block;
      padding: 12px 14px;
      position: relative;
      width: 100%;
      font-family: $agres-font-size;
      background: $agres-white;
      border: 2px solid $agres-border;
      border-radius: 0px;
      font-size: 15px;
      font-weight: 450;
      color: $agres-primary;
      caret-color: $agres-primary;
      -webkit-transition: border-color 0.2s;
      transition: border-color 0.2s;

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
      }

      &::before {
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        border-bottom: 0.4rem solid $agres-border;
        top: 45%;
      }

      &::after {
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        border-top: 0.4rem solid $agres-border;
        top: 45%;
      }
    }
  }


  &__input,
  &__textarea {
    border: 1px solid $agres-border;
    color: $agres-primary;
    background: $agres-white;
    border-radius: 0px;
    font-size: $agres-font-size;
    font-weight: 600;
    height: 45px;
    letter-spacing: 0;
    transition: border-color 0.2s;
    padding: 0 25px;
    width: 100%;
    
    &--alert {
      font-size: $agres-font-size-small;
      color: $agres-danger;
    }

    &:hover,
    &:focus {
      transition-duration: border-color 0.2s;
      outline: 0 none;
    }

    &::-webkit-input-placeholder {
      color: $agres-primary;
    }

    &::-moz-placeholder {
      color: $agres-primary;
    }

    &:-ms-input-placeholder {
      color: $agres-primary;
    }

    &::-ms-input-placeholder {
      color: $agres-primary;
    }

    &::placeholder {
      color: $agres-primary;
    }

    &::-ms-clear {
      display: none;
    }

    &:disabled[readonly] {
      background-color: $agres-primary;
      opacity: 0.5;
      border-bottom: 2px solid transparent;
      color: $agres-primary;
    }

    &[disabled]::-webkit-input-placeholder {
      color: $agres-primary;
    }

    &[disabled]:-ms-input-placeholder {
      color: $agres-primary;
    }

    &[disabled]::-ms-input-placeholder {
      color: $agres-primary;
    }

    &[disabled]::placeholder {
      color: $agres-primary;
    }
  }
}

input.ng-invalid.ng-touched {
  border-bottom: 2px solid $agres-danger;
}

input.ng-valid.ng-touched {
  border-bottom: 2px solid $agres-success;
}

.notify {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;

  &__warning {
    background-color: $agres-light-warning;
    color: $agres-primary;
  }

  &__danger {
    background-color: $agres-light-danger;
    color: $agres-danger;
  }

  &__info {
    background-color: $agres-light-info;
    color: $agres-info;
  }

  &__icon {
    padding: 10px;
  }

  p {
    margin-bottom: 0rem;
    font-weight: 500;
  }

  h1 {
    margin-bottom: 1.25rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__btns {
    display: flex;
    margin-top: 20px;
    padding-top: 20px;

    &:first-child {
      margin-right: 24px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .notify {
    &__btns {
      display: block;
      margin-top: 15px;
      padding-top: 15px;
      flex: 0 0 calc(50% - 8px);
      min-width: calc(50% - 8px);

      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.field__space {
  display: flex;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid $agres-border;
}

.field__btns {
  display: flex;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid $agres-border;

  &--margin {
    &:first-child {
      margin-right: 24px;
    }

    min-width: 175px;
  }
}

@media only screen and (max-width: 767px) {
  .field__btns {
    display: block;
    margin-top: 24px;
    padding-top: 24px;

    &--margin {
      &:first-child {
        margin-right: 8px;
      }

      flex: 0 0 calc(50% - 8px);
      min-width: calc(50% - 8px);
    }
  }
}