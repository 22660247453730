
.label {
    background-color: $agres-light-primary;
    color: $agres-primary;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
}

.nav .label,
.ibox .label {
    font-size: 10px;
}

.badge {
    background-color: $agres-light-primary;
    color: $agres-primary;
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    text-shadow: none;
}

.label-primary,
.badge-primary {
    background-color: $agres-primary;
    color: #ffffff;
}

.label-success,
.badge-success {
    background-color: $agres-info;
    color: #ffffff;
}

.label-warning,
.badge-warning {
    background-color: $agres-warning;
    color: #ffffff;
}

.label-warning-light,
.badge-warning-light {
    background-color: $agres-warning;
    color: #ffffff;
}

.label-danger,
.badge-danger {
    background-color: $agres-danger;
    color: #ffffff;
}

.label-info,
.badge-info {
    background-color: $agres-info;
    color: #ffffff;
}

.label-inverse,
.badge-inverse {
    background-color: #262626;
    color: #ffffff;
}

.label-white,
.badge-white {
    background-color: #ffffff;
    color: #5e5e5e;
}

.label-white,
.badge-disable {
    background-color: #2a2e36;
    color: #8b91a0;
}
