/*--- Grid ---*/
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 0px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76562rem;
    line-height: 1.5;
}


.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: 1.5rem, 1.5rem;
    padding-left: 1.5rem, 1.5rem;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}

.row {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * 0);
    margin-right: calc(-.5 * 1.5rem);
    margin-left: calc(-.5 * 1.5rem)
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(1.5rem * .5);
    padding-left: calc(1.5rem * .5);
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px;
}

.row-xs {
    margin-left: -5px;
    margin-right: -5px;
}

.row-xs>div {
    padding-left: 5px;
    padding-right: 5px;
}

@media (min-width: 576px) {
    .row-xs--sm {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--sm>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--sm {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--sm>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--sm {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--sm>div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .row-xs--md {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--md>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--md {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--md>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--md {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--md>div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .row-xs--lg {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--lg>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--lg {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--lg>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--lg {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--lg>div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .row-xs--xl {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--xl>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--xl {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--xl>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--xl {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--xl>div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1600px) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xxl-first {
        order: -1;
    }

    .order-xxl-last {
        order: 13;
    }

    .order-xxl-0 {
        order: 0;
    }

    .order-xxl-1 {
        order: 1;
    }

    .order-xxl-2 {
        order: 2;
    }

    .order-xxl-3 {
        order: 3;
    }

    .order-xxl-4 {
        order: 4;
    }

    .order-xxl-5 {
        order: 5;
    }

    .order-xxl-6 {
        order: 6;
    }

    .order-xxl-7 {
        order: 7;
    }

    .order-xxl-8 {
        order: 8;
    }

    .order-xxl-9 {
        order: 9;
    }

    .order-xxl-10 {
        order: 10;
    }

    .order-xxl-11 {
        order: 11;
    }

    .order-xxl-12 {
        order: 12;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66667%;
    }
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 600;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}


.flex-root {
    flex: 1
}

.flex-column-auto {
    flex: none
}

.flex-column-fluid {
    flex: 1 0 auto
}

.flex-row-auto {
    flex: 0 0 auto
}

.flex-row-fluid {
    flex: 1 auto;
    min-width: 0
}

.flex-center {
    justify-content: center;
    align-items: center
}

.flex-stack {
    justify-content: space-between;
    align-items: center
}

@media (min-width:576px) {
    .flex-sm-root {
        flex: 1
    }

    .flex-sm-column-auto {
        flex: none
    }

    .flex-sm-column-fluid {
        flex: 1 0 auto
    }

    .flex-sm-row-auto {
        flex: 0 0 auto
    }

    .flex-sm-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-sm-center {
        justify-content: center;
        align-items: center
    }

    .flex-sm-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width:768px) {
    .flex-md-root {
        flex: 1
    }

    .flex-md-column-auto {
        flex: none
    }

    .flex-md-column-fluid {
        flex: 1 0 auto
    }

    .flex-md-row-auto {
        flex: 0 0 auto
    }

    .flex-md-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-md-center {
        justify-content: center;
        align-items: center
    }

    .flex-md-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width:992px) {
    .flex-lg-root {
        flex: 1
    }

    .flex-lg-column-auto {
        flex: none
    }

    .flex-lg-column-fluid {
        flex: 1 0 auto
    }

    .flex-lg-row-auto {
        flex: 0 0 auto;
    }

    .flex-lg-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-lg-center {
        justify-content: center;
        align-items: center
    }

    .flex-lg-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width:1200px) {
    .flex-xl-root {
        flex: 1
    }

    .flex-xl-column-auto {
        flex: none
    }

    .flex-xl-column-fluid {
        flex: 1 0 auto
    }

    .flex-xl-row-auto {
        flex: 0 0 auto
    }

    .flex-xl-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-xl-center {
        justify-content: center;
        align-items: center
    }

    .flex-xl-stack {
        justify-content: space-between;
        align-items: center
    }
}

@media (min-width:1400px) {
    .flex-xxl-root {
        flex: 1
    }

    .flex-xxl-column-auto {
        flex: none
    }

    .flex-xxl-column-fluid {
        flex: 1 0 auto
    }

    .flex-xxl-row-auto {
        flex: 0 0 auto
    }

    .flex-xxl-row-fluid {
        flex: 1 auto;
        min-width: 0
    }

    .flex-xxl-center {
        justify-content: center;
        align-items: center
    }

    .flex-xxl-stack {
        justify-content: space-between;
        align-items: center
    }
}

.flex-equal {
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0
}


.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: 100%;
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    padding-top: 100%
}

.ratio-4x3 {
    padding-top: 75%
}

.ratio-16x9 {
    padding-top: 56.25%
}

.ratio-21x9 {
    padding-top: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-5 {
    opacity: .05 !important
}

.opacity-10 {
    opacity: .1 !important
}

.opacity-15 {
    opacity: .15 !important
}

.opacity-20 {
    opacity: .2 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1.5rem .5rem rgba(0, 0, 0, .075) !important
}

.shadow-sm {
    box-shadow: 0 .1rem 1rem .25rem rgba(0, 0, 0, .05) !important
}

.shadow-lg {
    box-shadow: 0 1rem 2rem 1rem rgba(0, 0, 0, .1) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-25 {
    top: 25% !important
}

.top-50 {
    top: 50% !important
}

.top-75 {
    top: 75% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-25 {
    bottom: 25% !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-75 {
    bottom: 75% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-25 {
    left: 25% !important
}

.start-50 {
    left: 50% !important
}

.start-75 {
    left: 75% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-25 {
    right: 25% !important
}

.end-50 {
    right: 50% !important
}

.end-75 {
    right: 75% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}
.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 0.75rem !important
}

.mt-4 {
    margin-top: 1rem !important
}

.mt-5 {
    margin-top: 1.25rem !important
}

.mt-6 {
    margin-top: 1.5rem !important
}

.mt-7 {
    margin-top: 1.75rem !important
}

.mt-8 {
    margin-top: 2rem !important
}

.mt-9 {
    margin-top: 2.25rem !important
}

.mt-10 {
    margin-top: 2.5rem !important
}

.mt-11 {
    margin-top: 2.75rem !important
}

.mt-12 {
    margin-top: 3rem !important
}

.mt-13 {
    margin-top: 3.25rem !important
}

.mt-14 {
    margin-top: 3.5rem !important
}

.mt-15 {
    margin-top: 3.75rem !important
}

.mt-16 {
    margin-top: 4rem !important
}

.mt-17 {
    margin-top: 4.25rem !important
}

.mt-18 {
    margin-top: 4.5rem !important
}

.mt-19 {
    margin-top: 4.75rem !important
}

.mt-20 {
    margin-top: 5rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: .75rem !important
}

.me-4 {
    margin-right: 1rem !important
}

.me-5 {
    margin-right: 1.25rem !important
}

.me-6 {
    margin-right: 1.5rem !important
}

.me-7 {
    margin-right: 1.75rem !important
}

.me-8 {
    margin-right: 2rem !important
}

.me-9 {
    margin-right: 2.25rem !important
}

.me-10 {
    margin-right: 2.5rem !important
}

.me-11 {
    margin-right: 2.75rem !important
}

.me-12 {
    margin-right: 3rem !important
}

.me-13 {
    margin-right: 3.25rem !important
}

.me-14 {
    margin-right: 3.5rem !important
}

.me-15 {
    margin-right: 3.75rem !important
}

.me-16 {
    margin-right: 4rem !important
}

.me-17 {
    margin-right: 4.25rem !important
}

.me-18 {
    margin-right: 4.5rem !important
}

.me-19 {
    margin-right: 4.75rem !important
}

.me-20 {
    margin-right: 5rem !important
}

.me-auto {
    margin-right: auto !important
}


.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: .75rem !important
}

.mb-4 {
    margin-bottom: 1rem !important
}

.mb-5 {
    margin-bottom: 1.25rem !important
}

.mb-6 {
    margin-bottom: 1.5rem !important
}

.mb-7 {
    margin-bottom: 1.75rem !important
}

.mb-8 {
    margin-bottom: 2rem !important
}

.mb-9 {
    margin-bottom: 2.25rem !important
}

.mb-10 {
    margin-bottom: 2.5rem !important
}

.mb-11 {
    margin-bottom: 2.75rem !important
}

.mb-12 {
    margin-bottom: 3rem !important
}

.mb-13 {
    margin-bottom: 3.25rem !important
}

.mb-14 {
    margin-bottom: 3.5rem !important
}

.mb-15 {
    margin-bottom: 3.75rem !important
}

.mb-16 {
    margin-bottom: 4rem !important
}

.mb-17 {
    margin-bottom: 4.25rem !important
}

.mb-18 {
    margin-bottom: 4.5rem !important
}

.mb-19 {
    margin-bottom: 4.75rem !important
}

.mb-20 {
    margin-bottom: 5rem !important
}

.mb-auto {
    margin-bottom: auto !important
}


.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: .75rem !important
}

.pt-4 {
    padding-top: 1rem !important
}

.pt-5 {
    padding-top: 1.25rem !important
}

.pt-6 {
    padding-top: 1.5rem !important
}

.pt-7 {
    padding-top: 1.75rem !important
}

.pt-8 {
    padding-top: 2rem !important
}

.pt-9 {
    padding-top: 2.25rem !important
}

.pt-10 {
    padding-top: 2.5rem !important
}

.pt-11 {
    padding-top: 2.75rem !important
}

.pt-12 {
    padding-top: 3rem !important
}

.pt-13 {
    padding-top: 3.25rem !important
}

.pt-14 {
    padding-top: 3.5rem !important
}

.pt-15 {
    padding-top: 3.75rem !important
}

.pt-16 {
    padding-top: 4rem !important
}

.pt-17 {
    padding-top: 4.25rem !important
}

.pt-18 {
    padding-top: 4.5rem !important
}

.pt-19 {
    padding-top: 4.75rem !important
}

.pt-20 {
    padding-top: 5rem !important
}


.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: .75rem !important
}

.pb-4 {
    padding-bottom: 1rem !important
}

.pb-5 {
    padding-bottom: 1.25rem !important
}

.pb-6 {
    padding-bottom: 1.5rem !important
}

.pb-7 {
    padding-bottom: 1.75rem !important
}

.pb-8 {
    padding-bottom: 2rem !important
}

.pb-9 {
    padding-bottom: 2.25rem !important
}

.pb-10 {
    padding-bottom: 2.5rem !important
}

.pb-11 {
    padding-bottom: 2.75rem !important
}

.pb-12 {
    padding-bottom: 3rem !important
}

.pb-13 {
    padding-bottom: 3.25rem !important
}

.pb-14 {
    padding-bottom: 3.5rem !important
}

.pb-15 {
    padding-bottom: 3.75rem !important
}

.pb-16 {
    padding-bottom: 4rem !important
}

.pb-17 {
    padding-bottom: 4.25rem !important
}

.pb-18 {
    padding-bottom: 4.5rem !important
}

.pb-19 {
    padding-bottom: 4.75rem !important
}

.pb-20 {
    padding-bottom: 5rem !important
}


.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}
