/*-------------------------------------------------------------------*/
/* === Design System - Agres Hydrogen === */
/*-------------------------------------------------------------------*/

@charset "UTF-8";

$agres-font: 'Agres Sans', sans-serif;
$agres-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$agres-font-size-small: clamp(0.75rem, 0.7065rem + 0.2174vi, 0.875rem);
$agres-font-size: clamp(0.875rem, 0.8315rem + 0.2174vi, 0.9375rem);
$agres-font-size-medium: clamp(1rem, 0.9565rem + 0.2174vi, 1.125rem);
$agres-font-size-large: clamp(1.125rem, 1.0815rem + 0.2174vi, 1.25rem);

$agres-font-weight: 400;
$agres-font-weight-medium: 500;;
$agres-font-weight-strong: 600;
$agres-line-height: 20px;
$agres-letter-spacing: 0.32px;


/*-------------------------------------------------------------------*/
/* === Design System - Color Base & Primary IsoFarm === */
/*-------------------------------------------------------------------*/

$agres-primary: hsl(270, 1%, 31%);
$agres-secondary: hsl(260, 1%, 54%);
$agres-success: hsl(140, 55%, 35%);
$agres-info: hsl(219, 44%, 30%);
$agres-danger: hsl(359, 61%, 37%);
$agres-warning: hsl(46, 100%, 47%);
$agres-alert: hsl(31, 89%, 65%);
$agres-white: hsl(0, 0%, 100%);
$agres-dark: hsl(260, 2%, 26%);
$agres-border: hsla(0, 0%, 0%, 0.10);
$agres-menu: hsl(233, 8%, 59%);
$agres-background: hsl(0, 0%, 98%);
$agres-title: hsl(270, 1%, 31%);
$agres-description: hsl(230, 8%, 44%);
$agres-label: hsl(206, 20%, 34%);
$agres-border-table: hsl(0, 0%, 95%);
$agres-hover-table: hsl(0, 0%, 96%);
$agres-primary-hover: hsl(280, 2%, 34%);
$agres-placeholder: hsl(0, 0%, 73%);
$agres-disabled:hsl(0, 0%, 80%);
$agres-return: hsl(0, 0%, 92%);
$agres-return-hover: hsl(0, 0%, 88%);


/*-------------------------------------------------------------------*/
/* === Design System - Color Light IsoFarm === */
/*-------------------------------------------------------------------*/

$agres-primary-alt: hsl(0, 0%, 96%);
$agres-light-primary: hsl(0, 0%, 96%);
$agres-light-secondary: hsl(300, 2%, 88%);
$agres-light-success: hsl(155, 60%, 88%);
$agres-light-info: hsl(207, 100%, 84%);
$agres-light-danger: hsl(351, 37%, 93%);
$agres-light-warning: hsl(49, 68%, 85%);
$agres-light-alert: hsl(38, 100%, 87%);


/*-------------------------------------------------------------------*/
/* === Design System - Box-Shadow IsoFarm === */
/*-------------------------------------------------------------------*/

$agres-shadow-primary: 0 0 0 0.25rem rgba(91, 91, 95, 0.5);
$agres-shadow-secondary: 0 0 0 0.25rem rgba(188, 196, 220, 0.5);
$agres-shadow-success: 0 0 0 0.25rem rgba(8, 210, 111, 0.5);
$agres-shadow-danger: 0 0 0 0.25rem rgba(252, 79, 104, 0.5);
$agres-shadow-warning: 0 0 0 0.25rem rgba(255, 228, 17, 0.5);
$agres-shadow-info: 0 0 0 0.25rem rgba(24, 127, 234, 0.5);
$agres-shadow-light: 0 0 0 0.25rem rgba(245, 246, 248, 0.5);
$agres-shadow-dark: 0 0 0 0.25rem rgba(0, 10, 0, 0.5);
$agres-shadow-dropdown: 0px 8px 24px rgba(149, 157, 165, 0.2);
$agres-shadow-sidebar: 0 0 500px rgba(56, 56, 56, 0.7);

/*-------------------------------------------------------------------*/
/* === Design System - Sidebar & Header IsoFarm === */
/*-------------------------------------------------------------------*/

$agres-sidebar-width: 260px;

/*-------------------------------------------------------------------*/
/* === Design System - Spins IsoFarm === */
/*-------------------------------------------------------------------*/

$spin-margin: 0 auto;

/*-------------------------------------------------------------------*/
/* === TODO: Remover futuramente === */
/*-------------------------------------------------------------------*/
$arrow-select: url('../../images/default/arrow.svg');

/*-------------------------------------------------------------------*/
/* === Responsive Breakpoint SCSS Agres Mixin: Não remover é a base do responsive */
/*-------------------------------------------------------------------*/

@mixin devices ($breakpoint) {

    @if $breakpoint ==mobile {
        @media only screen and (max-width: 480px) {
            @content;
        }
    }

    @if $breakpoint ==tablet {
        @media only screen and (max-width: 1024px) {
            @content;
        }
    }

    @if $breakpoint ==desktop {
        @media only screen and (min-width: 1023px) {
            @content;
        }
    }
}

/*-------------------------------------------------------------------*/
/* === News Icons Agres === */
/*-------------------------------------------------------------------*/

$agres-font-lg: 1.3333em;
$agres-font-xl: 1.5em;
$agres-xxs: .5em;
$agres-xs : .75em;
$agres-sm : .875em;
$agres-1x : 1em;
$agres-2x : 2em;
$agres-3x : 3em;
$agres-4x : 4em;
$agres-5x : 5em;
$agres-6x : 6em;
$agres-7x : 7em;
$agres-8x : 8em;
$agres-9x : 9em;
$agres-10x : 10em;
