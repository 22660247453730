.agr--breadcrump {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    position: relative;
    min-height: 50px;
    border-bottom: 1px solid transparent;
  
    &__left {
      background: none;
      padding-left: 0 !important;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      list-style: none;
      border-radius: 0px;
  
      &--item {
        padding-left: 0.5rem;
  
        &.active {
          color: $agres-danger;
          font-weight: 600;
        }
  
        &::before {
          display: inline-block;
          padding-right: 0.5rem;
          color: $agres-light-primary;
          content: '/';
          float: none !important;
        }
      }
    }
  
    &__right {
      margin-bottom: 1rem;
      padding: 0;
    }
  }
  @media (max-width: 991px) {
    .agr--breadcrump {
      display: block;
      margin: 0rem 0rem 1.25rem 0rem;
  
      &__left {
        margin-bottom: 1rem;
      }
  
      &__right {
        text-align: left;
        margin-bottom: 0rem;
      }
    }
  }

.breadcrumbs {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    box-shadow: 1px 2px 5px 0 rgba(155, 155, 155, 0.06);
    margin-bottom: 1rem;

    &__body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        padding: 1rem 1rem;

        &--left {
            flex-grow: 1;
            max-width: 100%;

            h5 {
                font-size: $agres-font-size;
                width: auto;
                padding: 1rem;
                border-left: 0.26rem solid #676a6c;
                margin-left: -1rem;
            }
        }

        &--right {
            flex-grow: 0;
            max-width: 100%;

            &---text {
                margin-right: 0.9375rem;
                margin-top: 0.9375rem;
            }
        }
    }
}

@media screen and (max-width: 61.875rem) {
    .breadcrumbs {
        &__body {
            &--right {
                &---text {
                    display: none;
                }
                &---buttons {
                    display: none;
                }
                &---dropdown-mobile{
                    display: flex;
                    margin-right: 0.625rem;
                    margin-top: 0.625rem;
                }
            }
        }
    }
}
