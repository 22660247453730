/*!
* Licensed Hydrogen - Property of Agres
* © Agres. 2023. All Rights Reserved.
* Restricted Rights to Users - Use, duplication or
* disclosure must have prior authorization from Agres.
*/

*,
*::before,
*::after {
  border: 0 solid;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: $agres-light-secondary;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: $agres-border;
}

::-webkit-scrollbar-thumb:hover {
  background: $agres-secondary;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::-moz-selection {
  background-color: $agres-primary;
  color: $agres-white;
}

::selection {
  background-color: $agres-primary;
  color: $agres-white;
}

*,
*:before,
*:after {
  box-sizing: inherit
}

body,
html {
  margin: 0;
  font-family: $agres-font;
  font-size: $agres-font-size;
  font-weight: $agres-font-weight;
  line-height: $agres-line-height;
  color: $agres-primary;
  text-align: left;
  background-color: $agres-white;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  height: 100%;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: $agres-white;
  letter-spacing: 0.1px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img,
fieldset,
a img {
  border: none;
}

a {
  color: $agres-description;
  text-decoration: none;
  cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
  border-radius: 0;
}

input,
button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
}

button {
  border-radius: 0;
  line-height: 1;
  cursor: pointer;
  background: transparent;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
  border-radius: 0;
}

ul,
ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}