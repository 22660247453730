/* latin */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-300-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-ext-300-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-vietnamese-300-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-cyrillic-ext-300-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-400-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-ext-400-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-vietnamese-400-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-cyrillic-ext-400-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-500-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-ext-500-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-vietnamese-500-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-cyrillic-ext-500-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-600-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-ext-600-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-vietnamese-600-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-cyrillic-ext-600-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-700-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-ext-700-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-vietnamese-700-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-cyrillic-ext-700-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-800-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-latin-ext-800-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-vietnamese-800-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Agres Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src:
    url(~assets/fonts/AgresSans-cyrillic-ext-800-normal.woff2) format('woff2'),
    url(~assets/fonts/AgresSans-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
