
/*-------------------------------------------------------------------*/
/* === Design System - IsoFarm Oxygen 2.3 === */
/*-------------------------------------------------------------------*/

/*-------------------------------------------------------------------*/
/* === Imports Variables Isofarm === */
/*-------------------------------------------------------------------*/

@import 'assets/styles/imports/variables';

/*-------------------------------------------------------------------*/
/* === Imports Base Isofarm === */
/*-------------------------------------------------------------------*/

@import 'assets/styles/imports/base';

/*-------------------------------------------------------------------*/
/* === Imports Icons Isofarm === */
/*-------------------------------------------------------------------*/

// TODO: Conforme Atualização será removido
@import 'assets/styles/imports/icons'; 

/*-------------------------------------------------------------------*/
/* === Imports Buttons Isofarm === */
/*-------------------------------------------------------------------*/

@import 'assets/styles/imports/buttons';

/*-------------------------------------------------------------------*/
/* === Imports Fonts Isofarm === */
/*-------------------------------------------------------------------*/

@import 'assets/styles/imports/fonts';

/*-------------------------------------------------------------------*/
/* === Imports Fonts Isofarm === */
/*-------------------------------------------------------------------*/

@import 'assets/styles/imports/dropdown';

/*-------------------------------------------------------------------*/
/* === Styes Panel Global === */
/*-------------------------------------------------------------------*/

@import 'assets/styles/imports/panel';

@import 'assets/styles/imports/responsive'; // AGRES Responsive
@import 'assets/styles/imports/input'; // AGRES Form-Control
@import 'assets/styles/imports/typography';
@import 'assets/styles/imports/breadcrumbs';
@import 'assets/styles/imports/table';
@import 'assets/styles/imports/grid';
@import 'assets/styles/imports/cards';
@import 'assets/styles/imports/navigation';
@import 'assets/styles/imports/top_navigation';
@import 'assets/styles/imports/buttons';
@import 'assets/styles/imports/badges_labels';
@import 'assets/styles/imports/sidebar';
@import 'assets/styles/imports/pages';
@import 'assets/styles/imports/chat';
@import 'assets/styles/imports/metismenu';
@import 'assets/styles/imports/spinners';

// Landing page styles
@import 'assets/styles/imports/landing';
// RTL Support
@import 'assets/styles/imports/rtl';
// For demo only - config box style
@import 'assets/styles/imports/theme-config';
// INSPINIA Skins
@import 'assets/styles/imports/skins';
@import 'assets/styles/imports/md-skin';
// Media query style
@import 'assets/styles/imports/media';
// Custom style
// Your custom style to override base style
@import 'assets/styles/imports/custom';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
